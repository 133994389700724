.settings-enter {
  margin-left: 9vh;
  transform: translateX(-11vh);
}
.settings-enter-active {
  margin-left: 20vh;
  transform: translateX(0);
  transition: margin-left 300ms, transform 300ms;
}
.settings-exit {
  margin-left: 20vh;
  transform: translateX(11vh);
}
.settings-exit-active {
  margin-left: 9vh;
  transform: translateX(0);
  transition: margin-left 300ms, transform 300ms;
}
